import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import "./App.css";
import abi from './abi';
import ERC20ABI from './ERC20ABI.json';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';


function App() {

    // 1. Get projectId
    const projectId = process.env.REACT_APP_PROJECT_ID;

    // 2. Set chains
    const mainnet = {
      chainId: 1,
      name: 'Ethereum',
      currency: 'ETH',
      explorerUrl: 'https://etherscan.io',
      rpcUrl: 'https://cloudflare-eth.com'
    }
    const bsc = {
      chainId: 56,
      name: 'BNB Smart Chain',
      currency: 'BNB',
      explorerUrl: 'https://bscscan.com',
      rpcUrl: 'https://bsc-dataseed.binance.org'
    }
    const polygon = {
      chainId: 137,
      name: 'Polygon Mainnet',
      currency: 'MATIC',
      explorerUrl: 'https://polygonscan.com',
      rpcUrl: 'https://polygon-rpc.com'
    }
    const base = {
      chainId: 8453,
      name: 'Base Network',
      currency: 'ETH',
      explorerUrl: 'https://basescan.org',
      rpcUrl: 'https://developer-access-mainnet.base.org'
    }
    const avax = {
      chainId: 43114,
      name: 'Avalanche C-Chain',
      currency: 'AVAX',
      explorerUrl: 'https://snowtrace.io',
      rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
    }
    const ftm = {
      chainId: 250,
      name: 'Fantom Opera',
      currency: 'FTM',
      explorerUrl: 'https://ftmscan.com',
      rpcUrl: 'https://rpc.ftm.tools'
    }
    const core = {
      chainId: 1116,
      name: 'Core Blockchain',
      currency: 'CORE',
      explorerUrl: 'https://scan.coredao.org',
      rpcUrl: 'https://rpc.coredao.org'
    }
    const bscTestnet = {
      chainId: 97,
      name: 'BNB Smart Chain Testnet',
      currency: 'tBNB',
      explorerUrl: 'https://testnet.bscscan.com',
      rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
    }
    const knb = {
      chainId: 13600,
      name: 'Kronobit Networks',
      currency: 'KNB',
      explorerUrl: 'https://explorer.qbitscan.com',
      rpcUrl: 'https://mainnet-rpc.qbitscan.com'
    }
    const stratis = {
      chainId: 105105,
      name: 'Stratis Mainnet',
      currency: 'STRAX',
      explorerUrl: 'https://explorer.stratisevm.com',
      rpcUrl: 'https://rpc.stratisevm.com'
    }
    const arb = {
      chainId: 42161,
      name: 'Arbitrum One',
      currency: 'ETH',
      explorerUrl: 'https://arbiscan.io',
      rpcUrl: 'https://arb1.arbitrum.io/rpc'
    }


    // 3. Create a metadata object
    const metadata = {
      name: 'BTCEX Airdrop',
      description: 'BTCEX Airdrop',
      url: 'https://airdrop.bitcoinexchange.finance', // origin must match your domain & subdomain
      icons: ['https://0xfactory.com/icons']
    }

    // 4. Create Ethers config
    const ethersConfig = defaultConfig({
      /*Required*/
      metadata,

      /*Optional*/
      enableEIP6963: true, // true by default
      enableInjected: true, // true by default
      enableCoinbase: true, // true by default
      //rpcUrl: '...', // used for the Coinbase SDK
      defaultChainId: 56 // used for the Coinbase SDK
    })

    // 5. Create a Web3Modal instance
    createWeb3Modal({
      ethersConfig,
      chains: [bsc, mainnet, polygon, base, arb, avax, ftm, bscTestnet, core, knb, stratis],
      projectId,
      enableAnalytics: true // Optional - defaults to your Cloud configuration
    })

    // 6. Use modal hook
    const { open } = useWeb3Modal();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { switchNetwork } = useSwitchNetwork();

  const [contractAddress, setContractAddress] = useState(null);
  const [fee, setFee] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [airdropAmount, setAirdropAmount] = useState(null);
  const [airdropType, setAirdropType] = useState("single");
  const [adminWallet, setAdminWallet] = useState("0x");
  const [chainIDx, setChainIDx] = useState(null);
  const [chainName, setChainName] = useState(null);


  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setContractAddress(response.data[0].value);
      setFee(response.data[1].value);
      setTitle(response.data[2].value);
      setDescription(response.data[3].value);
      setTokenContract(response.data[4].value);
      setTokenSymbol(response.data[5].value);
      setAirdropAmount(response.data[6].value);
      setAirdropType(response.data[7].value);
      setAdminWallet(response.data[8].value);
      setChainName(response.data[9].value);
      setChainIDx(response.data[10].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const connectWalletButton = () => {
    return (
      <button className="btn-claim" onClick={() => open()} tabindex="0" type="button">
        Wallet Connect
      </button>
    )
  }

  const airdropButton = () => {
    return (
      <button className="btn-claim" onClick={airdropHandler} tabindex="0" type="button">
        Claim Airdrop
      </button>
    )
  }

  function isAdmin(){
    if(adminWallet === null || address === null){
      return false;
    }
    if(adminWallet.toLowerCase() === String(address).toLowerCase()){
      return true;
    }
    return false;
  }


  useEffect(() => {
    fetchData();
  }, [])


  const airdropHandler = async() => {
    if(chainId !== Number(chainIDx)){
      alert("Please Switch MetaMask to " + chainName);
      switchNetwork(Number(chainIDx));
      return;
    }
    try{

      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(contractAddress, abi, signer);

        // Get the token balance
        const tokenBal = new Contract(tokenContract, ERC20ABI, provider);
        const balance = await tokenBal.balanceOf(address);
        if(formatUnits(balance, 18) > 0 && airdropType === "single"){
          alert('Sorry you can not claim because your wallet already own this token!');
          return;
        };

        console.log("Intialize payment");
        let getadrp;
        getadrp = await contract.dropTokens({value: fee});
        // if(airdropType === "single"){
        //   getadrp = await contract.dropTokens2({value: fee});
        // }else{
        //   getadrp = await contract.dropTokens({value: fee});
        // }

        console.log(contract);
		if(getadrp){
			alert("Congratulations, you got our airdrop, you will receive your tokens very soon");
		}else{
      if(airdropType === "single"){
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee or your wallet already have this token!");
      }else{
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee!");
      }
		}
      }
    }catch(err){
      if(airdropType === "single"){
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee.");
      }else{
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee!");
      }
    	console.log(err);
    }

  }

  const withdrawTokens = async() => {
    // alert(contractAddress);
    try{
      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(contractAddress, abi, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawTokens(adminWallet, tokenContract);

        if(withdrawRes){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Only the owner wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the owner wallet who can withdraw.");
          console.log(err);
        }

  }

  return (
    <div className="div-self">
    {isConnected && <w3m-button /> }
      <div className="card-s">
        <div className="card-header-s">
          <div className="flex-logo">
            <div className="logo-div">
              <img
                src="logo.png"
                className="company-logo"
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="d-flex-auto">
            <span className="header-text-s">{title}</span>
          </div>
        </div>

        <div className="mt-15">
          <img
            className="banner-img"
            src="banner.png"
            height="200"
            alt="Banner"
          />
        </div>

        <div>
          <div className="text-center">
            <p className="para-s">
              {description}
            </p>
          </div>
          <div className="contract-div">
            <p>
              Contract :{" "}
              <span className="contract-font">
                {tokenContract}
              </span>
            </p>
          </div>
          <h6 className="claim-h">Claim : {airdropAmount} {tokenSymbol}</h6>

          {isConnected ? airdropButton() : connectWalletButton()}
          <br />
          <br />
          {isAdmin() &&
            <div className="contract-div">
              <h5>Admin Area</h5>
              <p>
                Owner wallet only who can withdraw the tokens from the airdrop contract.
              </p>
              <button className="btn-claim" onClick={withdrawTokens}>
                Withdraw Tokens
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
}

export default App;
